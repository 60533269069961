.homeMain {
  width: 100%;
  margin: auto;
}

.carousel-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}

.carouselInner {
  display: flex;
  width: 100%;
  transition: transform 1s ease-in-out;
}

.carouselImage {
  min-width: 100%;
  max-height: 80vh;
  object-fit: cover;
  object-position: bottom;
}

.newArrivalsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
}
h2 {
  margin-bottom: 30px;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.4);
}

.collectionsOptions {
  width: 100%;
  height: 500px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1.25% 2.5%;
}

.collectionsOptions > .collection {
  width: 24%;
  height: 100%;
  border-radius: 14px;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.collectionsOptions > .collection > img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  object-fit: cover;
  opacity: 0.8;
  z-index: 1;
  border-radius: 14px;
  transition: opacity 0.3s ease;
}

.collectionsOptions > .collection > p {
  position: relative;
  z-index: 2;
  color: rgb(255, 255, 255);
  font-size: 24px;
  border-radius: 4px;
  margin-bottom: 20px;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.8), -2px -2px 4px rgba(0, 0, 0, 0.8),
    2px -2px 4px rgba(0, 0, 0, 0.8), -2px 2px 4px rgba(0, 0, 0, 0.8);
}

.collectionsOptions > .collection:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.collectionsOptions > .collection:hover > img {
  opacity: 1;
}

.newArrivalProductContainer {
  width: 95%;
  height: 400px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.newArrivalProductContainer > .newArrivalProduct {
  width: 19%;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 2px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.newArrivalProductContainer > .newArrivalProduct:hover {
  transform: scale(1.03);
}

.newArrivalProductContainer > .newArrivalProduct > .newArrivalProductTop {
  width: 100%;
  height: 80%;
}
.newArrivalProductContainer > .newArrivalProduct > .newArrivalProductTop > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-top-left-radius: 2px;
  border-top-right-radius: 2px;
}

.newArrivalProductContainer > .newArrivalProduct > .newArrivalProductBottom {
  width: 100%;
  height: 20%;
  padding: 4px 8px;
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > p {
  font-size: 14px;
  line-height: 21px;
}

.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .category_ {
  color: grey;
  font-size: 12px;
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .price_
  > b {
  font-size: 15px;
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .price_
  > s {
  color: rgb(173, 173, 173);
}
.newArrivalProductContainer
  > .newArrivalProduct
  > .newArrivalProductBottom
  > .price_
  > span {
  color: rgb(137, 187, 0);
}

.viewAllBtn {
  margin-top: 35px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  border-radius: 4px;
  padding: 6px 12px;
  font-size: 11px;
  cursor: pointer;
}
.viewAllBtn:hover {
  color: grey;
}

.imageBanner {
  width: 100%;
  margin: 40px 0px;
}
.imageBanner > img {
  width: 100%;
}

@media screen and (max-width: 768px) {
  .carouselImage {
    height: auto;
    object-fit: contain;
  }
  .newArrivalsContainer {
    margin-top: 15px;
  }
  .newArrivalsContainer > h2 {
    margin-bottom: 15px;
    font-size: 22px;
  }
  .newArrivalProductContainer {
    width: 90%;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 20px;
    justify-content: space-between;
  }

  .newArrivalProductContainer > .newArrivalProduct {
    width: 48%;
    height: 30vh;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    border-radius: 14px;
  }
  .newArrivalProductContainer
    > .newArrivalProduct
    > .newArrivalProductBottom
    > p {
    font-size: 10px;
    line-height: 14px;
  }

  .newArrivalProductContainer
    > .newArrivalProduct
    > .newArrivalProductBottom
    > .category_ {
    color: grey;
    font-size: 9px;
  }
  .newArrivalProductContainer
    > .newArrivalProduct
    > .newArrivalProductBottom
    > .price_
    > b {
    font-size: 11px;
  }
}
