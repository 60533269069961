.header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 40px;
  background-color: #fff;
  position: sticky;
  top: 0;
  z-index: 999999999999999;
  border-bottom: 1px solid rgb(182, 182, 182);
}

.header > .leftMenu {
  width: 40%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 20px;
}

.header > .leftMenu > ul {
  width: 100%;
  display: flex;
  align-items: center;
  list-style: none;
}

.header > .leftMenu > ul > .menuItem {
  cursor: pointer;
  font-weight: 500;
  margin-left: 28px;
}
.header > .leftMenu > ul > .menuItem:hover {
  color: grey;
}
.header > .logo {
  width: 20%;
  height: 100%;
  flex: 1;
  text-align: center;
}

.header > .logo > img {
  max-height: 40px;
}

.header > .rightButtons {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
  height: 100%;
  gap: 15px;
}

.header > .rightButtons > .searchBtn {
  width: 300px;
  padding: 10px;
  border: none;
  outline: none;
  border-radius: 7px;
  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}

.header > .rightButtons > .loginBtn {
  padding: 8px 15px;
  background-color: #000000;
  cursor: pointer;
  border-radius: 5px;
  border: 1px solid black;
  color: white;
  font-size: 16px;
}

.header > .rightButtons > .loginBtn:hover {
  background-color: white;
  color: black;
}
.hamburger {
  display: none;
}
.searchbar {
  display: none;
}
.sidebarOption {
  display: none;
}

@media screen and (max-width: 768px) {
  .header {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 10px;
    background-color: #fff;
  }

  .hamburger {
    display: block;
    font-size: 28px;
  }

  .searchbar {
    display: block;
    font-size: 28px;
  }

  .sidebarOption {
    display: block;
    position: absolute;
    width: 80%;
    min-height: 100vh;
    background-color: white;
    left: 0;
    top: 0;
  }

  .header > .leftMenu {
    display: none;
  }

  .header > .leftMenu > ul {
    display: none;
  }
  .header > .logo {
    width: 100%;
    height: 100%;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .header > .logo > img {
    width: 120px;
  }

  .header > .rightButtons {
    display: none;
    /* justify-content: flex-end;
    align-items: center;
    width: 40%;
    height: 100%;
    gap: 15px; */
  }

  .header > .rightButtons > .searchBtn {
    /* width: 300px;
    padding: 10px;
    border: none;
    outline: none;
    border-radius: 7px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
      rgba(27, 31, 35, 0.15) 0px 0px 0px 1px; */
    display: none;
  }

  .header > .rightButtons > .loginBtn {
    display: none;
  }
}
